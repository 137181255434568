import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'


const MyHelmet = ({ title, description, image, pathname, article }) => (
    <StaticQuery
        query={query}
        render={({
            site: {
                siteMetadata: {
                    defaultTitle,
                    titleTemplate,
                    defaultDescription,
                    siteUrl,
                    defaultImage,
                    social: {
                        creator,
                        socialTitle,
                        socialDescription
                    }
                }
            }
        }) => {
            const seoData = {
                title: title || defaultTitle,
                description: description || defaultDescription,
                image: `${ siteUrl }${ image || defaultImage }`,
                url: `${ siteUrl }${ pathname || '/' }`
            }
            return (
            <Helmet title={seoData.title} titleTemplate={titleTemplate}>
                <meta name="description" content={seoData.description} />
                <meta name="image" content={seoData.image} />
                {seoData.url && <meta property="og:url" content={seoData.url} />}
                {(article ? true : null) && (
                    <meta property="og:type" content="article" />
                )}
                {seoData.title && <meta property="og:title" content={seoData.title} />}
                {seoData.description && (
                <meta property="og:description" content={seoData.description} />
                )}
                {seoData.image && <meta property="og:image" content={seoData.image} />}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={creator} />
                <meta name="twitter:title" content={socialTitle} />
                <meta name="twitter:description" content={socialDescription} />
                {seoData.image && <meta name="twitter:image" content={seoData.image} />}
            </Helmet>);
        }}
    />
)

MyHelmet.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    pathname: PropTypes.string,
    article: PropTypes.bool
}

MyHelmet.defaultProps = {
    title: null,
    description: null,
    image: null,
    pathname: null,
    article: false,
}

export default MyHelmet

const query = graphql`
    query SiteMetadataQuery {
        site {
            siteMetadata {
                defaultTitle: title,
                titleTemplate,
                defaultDescription: description,
                siteUrl: url,
                defaultImage: image,
                social {
                    creator,
                    socialTitle: title,
                    socialDescription: description
                }
            }
        }
    }
`