import React from "react"
import { Button, Carousel } from "react-bootstrap"
import { Link } from "gatsby"
import sliderStyle from "./slider.module.css"

const Slicer = ({ data }) => (
  <Carousel className={sliderStyle.slider}>
    <Carousel.Item className={sliderStyle.item}>
      <img
        className="d-block w-100"
        src="/slider/car.png"
        alt="Taxi and Private Hire"
      />
      <Carousel.Caption className={sliderStyle.caption}>
        <h3>Taxi and Private Hire</h3>
        <p>
          Completing your PCO Licence will be hassle free. We are the cheapest
          and one of London’s only all in one centre where you can complete your
          Topographical training, CRB check, medical and have your application
          correctly filled in by us, taking the headache out of the PCO
          application process.
        </p>
        <Link to="/courses/taxi-and-private-hire">
          <Button variant="primary">Read More</Button>
        </Link>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item className={sliderStyle.item}>
      <img
        className="d-block w-100"
        src="/slider/math.png"
        alt="Functional Skills English and Mathematics"
      />

      <Carousel.Caption className={sliderStyle.caption}>
        <h3>Functional Skills English and Mathematics</h3>
        <p>
          Functional Skills English Level 2 can help you develop the practical
          skills you need to use English in life, study and work. You’ll develop
          your English skills and learn how to use them in real-life situations.
        </p>
        <Link to="/courses/functional-skills-english-mathematics">
          <Button variant="primary">Read More</Button>
        </Link>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item className={sliderStyle.item}>
      <img
        className="d-block w-100"
        src="/slider/scsc.png"
        alt="Construction Skills Certificate Scheme (CSCS)"
      />
      <Carousel.Caption className={sliderStyle.caption}>
        <h3>Construction Skills Certificate Scheme (CSCS)</h3>
        <p>
          This qualification is for anyone wishing to apply for the Construction
          Skills Certificate Scheme (CSCS) Green Card to work on a UK
          construction site.
        </p>
        <Link to="/courses/level-1-award-in-health-and-safety-in-a-construction-environment">
          <Button variant="primary">Read More</Button>
        </Link>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item className={sliderStyle.item}>
      <img
        className="d-block w-100"
        src="/slider/higher.png"
        alt="Further and Higher Education Courses"
      />
      <Carousel.Caption className={sliderStyle.caption}>
        <h3>Further and Higher Education Courses</h3>
        <p>Further and Higher Education Courses</p>
        <Link to="/courses/further-and-higher-education-courses">
          <Button variant="primary">Read More</Button>
        </Link>
      </Carousel.Caption>
    </Carousel.Item>
  </Carousel>
)

export default Slicer
