import React, { Component } from "react"
import { Link } from "gatsby"

import { FaPhone, FaMap, FaPaperPlane } from "react-icons/fa"

import "../styles/bootstrap.min.css"
import "../styles/style.css"

class Header extends Component {
  constructor(props) {
    super(props)
    this.toggleNavbar = this.toggleNavbar.bind(this)
    this.state = {
      collapsed: true,
    }
  }
  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    })
  }
  render() {
    const collapsed = this.state.collapsed
    const classOne = collapsed
      ? "collapse navbar-collapse"
      : "collapse navbar-collapse show collapse-style"
    const classTwo = collapsed
      ? "navbar-toggler navbar-toggler-right collapsed"
      : "navbar-toggler navbar-toggler-rightk"
    return (
      <header className="header" id="header">
        <div className="py-2 bg-primary">
          <div className="container">
            <div className="row d-flex">
              <div className="col-lg-12 d-block">
                <div className="row d-flex">
                  <div className="col-md-5 pr-4 d-flex topper ">
                    <div className="icon bg-fifth mr-2 d-flex justify-content-center align-items-center">
                      <span>
                        <FaMap />
                      </span>
                    </div>
                    <span className="text-white">
                      Adult Education College, London
                    </span>
                  </div>
                  <div className="col-md pr-4 d-flex topper align-items-center">
                    <div className="icon bg-secondary mr-2 d-flex justify-content-center align-items-center">
                      <span>
                        <FaPaperPlane />
                      </span>
                    </div>
                    <span className="text-white">info@laecollege.uk</span>
                  </div>
                  <div className="col-md pr-4 d-flex topper align-items-center">
                    <div className="icon bg-tertiary mr-2 d-flex justify-content-center align-items-center">
                      <span>
                        <FaPhone />
                      </span>
                    </div>
                    <span className="text-white">
                      07746829536 / 07853154750
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav
          className="navbar navbar-expand-lg navbar-dark sticky-top"
          style={{
            borderBottom: `1px solid rgba(0, 0, 0, 0.20)`,
            background: `#fff`,
          }}
        >
          <div className="container">
            <Link
              to="/"
              className="navbar-brand"
              style={{
                boxShadow: `none`,
                textDecoration: `none`,
                textTransform: `uppercase`,
              }}
            >
              <h5>
                <span className="text-primary">London Adult Education College</span>
              </h5>
            </Link>

            <button
              onClick={this.toggleNavbar}
              className={`${classTwo}`}
              style={{ backgroundColor: `#0043a4` }}
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className={`${classOne}`} id="navbarResponsive">
              <ul className="navbar-nav ml-auto navigation">
                <li className="nav-item active ">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item ">
                  <Link className="nav-link" to="/about">
                    About
                  </Link>
                </li>
                <li className="nav-item ">
                  <Link className="nav-link" to="/contact">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    )
  }
}

export default Header
