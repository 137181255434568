import React from "react"
import MyHelmet from './Helmet'
import Header from "./header"
import Footer from "./footer"
import Slider from "./slider"
import "../styles/bootstrap.min.css"
import "../styles/style.css"

const Layout = (props) => {

  return (
    <div>
      <MyHelmet {...props} />
      <Header />
      <Slider />
        <main className="">{props.children}</main>
      <Footer />
    </div>
  )
}

export default Layout
