import React from "react"
import { Link } from "gatsby"

import "../styles/style.css"

const Footer = () => {
  return (
    <footer className="footer-section">
      <div className="footer-upper">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-lg-3 col-sm-6 col-xs-12">
              <div className="footer-column">
                <div className="column-title">
                  <h3>About Us </h3>
                </div>
                <p>
                  We are the cheapest and one of London’s only all in one centre
                  where you can complete your Topographical training.
                </p>
                <br />
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-sm-6 col-xs-12">
              <div className="footer-column site-map-links">
                <div className="column-title">
                  <h3>Site Map</h3>
                </div>
                <ul style={{ lis: `none` }}>
                  <li>
                    {" "}
                    <Link
                      style={{
                        boxShadow: `none`,
                        textDecoration: `none`,
                        color: `white`,
                      }}
                      to={`/`}
                    >
                      Courses{" "}
                    </Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link
                      style={{
                        boxShadow: `none`,
                        textDecoration: `none`,
                        color: `white`,
                      }}
                      to={`/`}
                    >
                      Programs{" "}
                    </Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link
                      style={{
                        boxShadow: `none`,
                        textDecoration: `none`,
                        color: `white`,
                      }}
                      to={`/contact`}
                    >
                      Contact Us{" "}
                    </Link>{" "}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-sm-6 col-xs-12">
              <div className="footer-column site-map-links">
                <div className="column-title">
                  <h3>Support </h3>
                </div>
                <ul>
                  <li>
                    <span>
                      <a
                        href="https://bigiltech.com/"
                        target="_blank"
                        rel="noopener"
                        style={{
                          boxShadow: `none`,
                          textDecoration: `none`,
                          color: `white`,
                        }}
                      >
                        BigilTech
                      </a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-3 col-lg-3 col-sm-6 col-xs-12">
              <div className="footer-column site-map-links">
                <div className="column-title">
                  <h3>Contact Info</h3>
                </div>
                <ul>
                  <li>London, Uk</li>
                  <li>
                    Address:Printing house Lane, unit  21, Hayes, UB3 1AP

                  </li>
                </ul>
                <ul>
                  <i>
                    <span
                      style={{
                        boxShadow: `none`,
                        textDecoration: `none`,
                        color: `white`,
                      }}
                    >
                      Tel: 07746829536 (Mr Adan) <br />
                      Tel: 07853154750 (Dr. Hassan){" "} <br/>
                      Office Number: 07592764401
                    </span>
                  </i>
                </ul>
                <ul>
                  <li>
                    Opening Hours <br />
                     Monday - Saturday
                    <br /> 10.00AM - 18.00
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="auto-container fs-13">
          <p style={{ textAlign: "center" }}>
            &copy; Copyright 2019 by <span>Education Web</span> | All rights
            reserved BigilTech
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
